<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <div class="flex justify-content-start mb-4">
          <Button
            label="Back" @click="$router.go(-1)"
            icon="pi pi-arrow-left" class="p-button-link p-button-success mr-2"
          />
        </div>

        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <h5>Company Name: <i>{{ company?.name }}</i></h5>
              <h5>Admin Full Name:  <i>{{ company?.first_name + ' ' + company?.last_name }}</i></h5>
              <h5>Count Users:  <i>{{ company?.total }}</i></h5>
              <h5>Balance / In Progress: <b>{{ company?.balance }} / {{ company?.frozen_balance }} $</b></h5>
            </div>
          </template>
        </Toolbar>

        <DataTable
            :first="pagination?.from" :rows="pagination?.per_page ?? 25"
            :value="users" :lazy="true" :paginator="true" ref="dt" dataKey="id"
            :totalRecords="pagination?.total" :loading="loading" @page="onPage($event)"
            @sort="onSort($event)" :sortField="sort" :sortOrder="order"
            responsiveLayout="scroll"  :rowsPerPageOptions="[10,25,50]"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        >
          <template #empty>
            Not found.
          </template>

          <!-- Name -->
          <Column field="name" header="Name" :sortable="true" />

          <!-- Email -->
          <Column field="email" header="Email" :sortable="true" />

          <!-- Orders Made -->
          <Column field="orders_count" header="Orders Made" :sortable="true" />

          <!-- Status -->
          <Column field="status" header="Status" :sortable="true">
            <template #body="slotProps">
              <span :class="'product-badge status-' + statusText(slotProps.data.status)">
                {{ statusText(slotProps.data.status).toUpperCase() }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import companyResource from "@http/CompanyResource";
import toastParamBuilder from "@/builders/ToastParamBuilder";
import user from "@/models/User";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      id: null,
      loading: false,
      company: null,
      users: null,
      pagination: null,
      sort: null,
      order: null,
    }
  },

  created() {
    this.sort = this.$route.query?.sort;
    this.order = this.$route.query?.order !== 'desc' ? 1 : -1;
  },

  methods: {
    onPage(event) {
      this.loading = true;
      this.$router.push({
        name: this.$route.name,
        params: {id: this.id},
        query: {
          ...this.$route.query,
          page: event.page + 1,
          per_page: event.rows,
        }
      });
    },

    onSort(event) {
      this.loading = true;
      this.$router.push({
        name: this.$route.name,
        query: {
          ...this.$route.query,
          sort: event.sortField,
          order: event.sortOrder === 1 ? 'asc' : 'desc',
        }
      });
    },
  },

  computed: {
    statusText() {
      return status => this.statuses[status];
    },

    statuses() {
      return user.statuses.map(item => item.name);
    },

    ...mapGetters({
      company: 'company/getCompany'
    }),
  },

  async beforeRouteEnter(to, from, next) {
    try {
      let company = await companyResource.company(to.params.id);
      let users = await companyResource.getUsersByCompany(to.params.id, to.query);
      next((vm) => {
        vm.loading = false;
        vm.id = to.params.id;
        vm.company = company.data;
        vm.company.total = users.data.meta.total;
        vm.users = users.data.data;
        vm.pagination = users.data.meta;
      });
    } catch (error) {
      next((vm) => {
        vm.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
      });
    }
  },

  async beforeRouteUpdate(to) {
    try {
      let company = await companyResource.company(to.params.id);
      let users = await companyResource.getUsersByCompany(to.params.id, to.query);
      this.loading = false;
      this.id = to.params.id;
      this.company = company.data;
      this.company.total = users.data.meta.total;
      this.users = users.data.data;
      this.pagination = users.data.meta;
    } catch (error) {
      this.$toast.add(toastParamBuilder.error(error.message ? error.message : error));
    }
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/demo/badges.scss';
</style>
